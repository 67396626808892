import arrayMerge from 'Services/utils/arrayMerge';

export default function mergeCopyrightsResponses(responses) {
    const json = responses[0];
    if (responses.length > 1) {
        // this is edge of the world case
        json.regions = arrayMerge(responses[0].regions, responses[1].regions, function(first, second) {
            return first.country.ISO3 === second.country.ISO3;
        });
    }
    return json;
}
