function buildBoundingBox(minLat, minLon, maxLat, maxLon) {
    return {
        minLat,
        minLon,
        maxLat,
        maxLon
    };
}

export default function getMapBoundingBoxes(map) {
    const mapBounds = map.getBounds();
    let nwBoundsCorner = mapBounds.getNorthWest();
    let seBoundsCorner = mapBounds.getSouthEast();
    const boundingBoxes = [];
    if (Math.abs(seBoundsCorner.lng - nwBoundsCorner.lng) > 360) { //the whole world
        boundingBoxes.push(buildBoundingBox(seBoundsCorner.lat, -180, nwBoundsCorner.lat, 180));
    } else {
        nwBoundsCorner = nwBoundsCorner.wrap();
        seBoundsCorner = seBoundsCorner.wrap();

        if (nwBoundsCorner.lng > 0 && seBoundsCorner.lng < 0) { //anti meridian case
            boundingBoxes.push(buildBoundingBox(seBoundsCorner.lat, nwBoundsCorner.lng, nwBoundsCorner.lat, 180));
            boundingBoxes.push(buildBoundingBox(seBoundsCorner.lat, -180, nwBoundsCorner.lat, seBoundsCorner.lng));
        } else {
            boundingBoxes.push(buildBoundingBox(seBoundsCorner.lat, nwBoundsCorner.lng, nwBoundsCorner.lat,
                seBoundsCorner.lng));
        }
    }

    return boundingBoxes;
}
