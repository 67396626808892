import {isEmpty, join, escape} from 'lodash';

function getGeneralCopyrights(generalCopyrights) {
    if (!generalCopyrights || isEmpty(generalCopyrights)) {
        return '';
    }

    return join(generalCopyrights.map((generalCopyright) => `<p>${escape(generalCopyright)}</p>`), '');
}

function getCopyrights(copyrights) {
    if (!copyrights || isEmpty(copyrights)) {
        return '';
    }

    return join(copyrights.map((copyright) => `<p>${escape(copyright)}</p>`), '');
}

function getRegions(regions) {
    if (!regions || isEmpty(regions)) {
        return '';
    }

    return join(regions.map((region) => {
        return `<h4>${escape(region.country.label)}:</h4>` + getCopyrights(region.copyrights);
    }), '');
}

export default function renderCopyrights(json) {
    return '<div id="copyrightMessage">' +
        '<h4>General Copyrights:</h4>' +
        getGeneralCopyrights(json.generalCopyrights) +
        getRegions(json.regions) +
        '</div>';
}
